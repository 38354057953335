import React from 'react';
import {log, setDisplayValue, showSubPage} from "../elements/helpers";
import {Feedback_Form} from "../elements/feedback_form";
import back from "../../images/back.svg";
import {JWT_decoded} from "../elements/google_sso_login";
import Contact_Info from "../payment/contacts";

export default class Contact_us extends React.Component {

    // componentDidMount() {
    //     if(JWT_decoded) {
    //         document.getElementById("user_email").value = JWT_decoded["email"];
    //         // log("user_email: " + JWT_decoded["email"]);
    //         // setDisplayValue("user_email", "none");
    //     }
    // }

    render() {

        return (
            <div id="div_contact_us" className="div_sub_page">

                <div className="div_settings_header" style={{marginBottom: "20px"}}>
                    <img className="button button_back" src={back} onClick={btnBack_onClick}/>
                    <label className="settings_header">Contact us</label>
                </div>


                <Feedback_Form/>
                <Contact_Info/>
            </div>
        )
    }
}

////////////////////// EVENT HANDLERS /////////////////////////////////

const btnBack_onClick = async event => {
    await showSubPage("div_main_content");
    await window.Main_page.setState({loading: false}); //re-render the page
}

export const btnOpenContactUs_onClick= async event => {
    log("btnOpen_Contact_us_onClick");
    await showSubPage("div_contact_us");
}

