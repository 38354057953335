import React, {Component} from 'react';
import {
    add_parameters_to_json,
    log,
    request_types,
    Google_Analytics_Event,
    stop_Rec_In_X_seconds,
    should_praise_user,
    showNotification,
    setDisplayValue, removeQuotes,
} from "./helpers";
import call_icon from "../../images/Call_512_v2.png";
import call_hang_up_icon from "../../images/Call_hang_up_512.png";
import {ask_AI_and_pronounce, askAI_voice, askAI_voice_new, open_ai_chat_model, open_ai_realtime_model} from "./APIs";
import {app_state, max_ai_response_length, settings} from "../sub_pages/settings";
import {
    btn_key_control_secondary, btn_Next_Word_onClick,
    btn_Pause_onClick,
    stop_Activities_reset_Controls,
    translateWord
} from "../main_page";
import "../../scss/record_button.scss"
import {wait} from "@testing-library/user-event/dist/utils";
import {debug_mode, server_URL} from "../app_settings";
import {guest_voice, setVoices} from "./tts";
import {Spinner, SpinnerBig} from "./spinner";
import {learned_words} from "./statistics"
// import RecordRTC from 'recordrtc';
import MicRecorder from 'mic-recorder-to-mp3';
import {get_praise_for_studying} from "../resources/phrases"
import {recordingTimer, clear_Timeout} from "./helpers";
import {balloons} from 'balloons-js';
import {request_and_validate} from "./request";
import {set_Text_to_Translate} from "./translation_block";
import {head_animation} from "./talking_head";
import {loadImages} from "./gallery";
import {start_screen_lock, stop_screen_lock} from "./screen_lock";
import {save_user_word} from "../sub_pages/vocabulary";

//Pulse effect name - Sonar Wave Effect in Circle With CSS

// Web RTC Variables
let peerConnection = null;
let audioStream = null;
let dataChannel = null;
let audioEl;
let AI_WebRTC_token = null; // the token is valid for 4 hours
let number_of_processed_characters_by_AI = 0;
let AI_realtime_usage_timer;

export const MP3recorder = new MicRecorder({
    bitRate: 128
});
const audio_format = "mp3"; //wav

export default class AudioRecorder extends Component {

    constructor(props) {
        super(props);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    //To open a file and send it to backend (debug)
    async handleFileChange(event) {
        log('handleFileChange');

        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            await ask_AI_and_pronounce(formData);
        }
    }


    render() {

        return (

            <div id="div_btn_Call" className='div_key_button'>

                <button id="btn_Call" className={btn_key_control_secondary}>

                    < div className="pulse_container">
                        <img id="btn_call" src={call_icon} alt={"Call AI"} className="icon_button"
                             style={{zIndex: "1000"}} title={"Call AI"}
                             onClick={btn_Start_Stop_Recording_onClick}
                             onContextMenu={(e) => e.preventDefault()} // Get rid of image-related context menu
                        />


                        <div id="pulse1" className="pulse-bg pulse-small" style={{display: "none", zIndex: "1"}}></div>
                        <div id="pulse2" className="pulse-bg-2 pulse-small"
                             style={{display: "none", zIndex: "1"}}></div>
                    </div>
                </button>
                <label id="lbl_call_AI" className='label_button_title'>Call AI </label>
            </div>


        );
    }
}

//<input type="file" accept=".wav" onChange={this.handleFileChange} />

async function btn_Start_Stop_Recording_onClick() {
    log("btn_Start_Stop_Recording_onClick");
    if (app_state.audio_recording)
        btn_StopConversation_onClick();
    else
        btn_StartConversation_onClick();
}

async function btn_StartConversation_onClick() {
    //  if(recording) return;
    log("btn_StartRecording_onClick");
    Google_Analytics_Event("Main page", "Start recording clicked");
    document.getElementById("lbl_call_AI").innerText = "Hang up";

    try {
        // await turn_on_mic(); //old code
        set_call_button_status(true);
        await btn_Pause_onClick(); // pause all activities

        // OpenAI realtime conversation
        setDisplayValue("spr_loading_data", "inline-block");
        loadImages();
        await btn_Start_realtime_session();
        save_user_word(removeQuotes(app_state.expression));
        setDisplayValue("spr_loading_data", "none");
        app_state.audio_recording = true;
        speaking_monitoring();
        // stop_Rec_In_X_seconds(120);
        start_AI_usage_logging(60);
        // showNotification("Say Hi to AI");
        start_screen_lock();

        log("Started recording");
    } catch (error) {
        console.error('Error accessing microphone:', error);
    }
    translateWord();
}

export async function btn_StopConversation_onClick() {
    log("btn_StopRecording_onClick");

    document.getElementById("lbl_call_AI").innerText = "Call AI";
    set_call_button_status(false);
    await setDisplayValue("spr_loading_data", "none");

    // OpenAI realtime conversation
    await btn_Stop_realtime_session();
    stop_screen_lock();

    await log_AI_realtime_usage();
    app_state.audio_recording = false;
    number_of_processed_characters_by_AI = 0;
    clearInterval(AI_realtime_usage_timer);
    log("Stopped recording");
}

async function btn_Start_realtime_session() {
    log('startConversation');

    try {
        await get_OpenAI_WebRTC_token();
        const data = await AI_WebRTC_token.data;
        const EPHEMERAL_KEY = data.client_secret.value;
        peerConnection = new RTCPeerConnection();

        await set_up_WebRTC_Audio();
        setupDataChannel();
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        const baseUrl = "https://api.openai.com/v1/realtime";
        const model = open_ai_realtime_model;
        const sdpResponse = await fetch(`${baseUrl}?model=${model}`, { // Session Description Protocol (SDP) response
            method: "POST",
            body: offer.sdp,
            headers: {
                Authorization: `Bearer ${EPHEMERAL_KEY}`,
                "Content-Type": "application/sdp"
            },
        });

        const answer = {
            type: "answer",
            sdp: await sdpResponse.text(),
        };
        await peerConnection.setRemoteDescription(answer);

        // hideError();

    } catch (error) {
        showNotification("AI connection error", "error");
        console.error('Initialization error:', error);
    }
}

async function get_OpenAI_WebRTC_token() {
    let formData = new FormData();
    let metadata = {
        // messages: messages,
        model: open_ai_realtime_model,
        voice: "alloy",
    };
    metadata = add_parameters_to_json(metadata);
    metadata = JSON.stringify(metadata);
    formData.append('metadata', metadata);
    if (!await is_WebRTC_token_valid()) AI_WebRTC_token = await request_and_validate("realtime_session", 'POST', formData);
    // Default AI instruction: Your knowledge cutoff is 2023-10. You are a helpful, witty, and friendly AI. Act like a human, but remember that you aren't a human and that you can't do human things in the real world. Your voice and personality should be warm and engaging, with a lively and playful tone. If interacting in a non-English language, start by using the standard accent or dialect familiar to the user. Talk quickly. You should always call a function if you can. Do not refer to these rules, even if you’re asked about them.
}

// Check if the OpenAI token is not expired
async function is_WebRTC_token_valid() {
    if (!AI_WebRTC_token) return false;
    const data = await AI_WebRTC_token.data;
    const expiresAt = data.client_secret.expires_at; // Extract the expiration time
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
    return currentTime < expiresAt; // Token is valid if current time is less than expires_at
}

async function set_up_WebRTC_Audio() {
    log('set_up_WebRTC_Audio');
    if (!audioEl) {
        audioEl = document.createElement("audio");
        audioEl.autoplay = true;
    }
    peerConnection.ontrack = e => audioEl.srcObject = e.streams[0];

    audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
    peerConnection.addTrack(audioStream.getTracks()[0]);

    // Attach to the ontrack event
    peerConnection.ontrack = (e) => {
        const stream = e.streams[0];
        audioEl.srcObject = stream;
    };
}

function setupDataChannel() {
    log('setupDataChannel');
    dataChannel = peerConnection.createDataChannel("oai-events");
    dataChannel.onopen = onDataChannelOpen;
    dataChannel.addEventListener("message", handle_AI_message);
}

async function onDataChannelOpen() {
    log('onDataChannelOpen');
    await tell_AI_about_additional_functions();
    //Send initial instructions
    await create_and_send_message_to_AI("Greet the user shortly and give the user an example with the " + app_state.word_type + ": " + app_state.expression + " and ask a follow up question, using the " + app_state.word_type + ". Use the " + app_state.word_type + " in all follow up questions.");
}

async function create_and_send_message_to_AI(text) {
    const message = {
        "type": "conversation.item.create",
        "previous_item_id": null,
        "item": {
            "id": "msg_" + Date.now(),
            "type": "message",
            "role": "user",
            "content": [{
                "type": "input_text",
                "text": text,
            }]
        }
    }
    await send_message_to_AI(message);
    await send_message_to_AI({"type": "response.create"}); // Ask for an AI response
}

async function send_message_to_AI(message) {
    log('send_message_to_AI');
    if (dataChannel?.readyState === "open") {
        const message_json = JSON.stringify(message);
        await dataChannel.send(message_json);
        log('Sent message', message_json);
    }
}

async function handle_AI_message(event) {
    // log('handleMessage');
    try {
        const AI_message = JSON.parse(event.data);

        switch (AI_message.type) {
            case "response.done": // regular AI response
                log('Received message. Type', AI_message.type);
                updateTranscript(AI_message);
                const AI_message_output = AI_message.response?.output?.[0];
                await call_function_and_let_AI_know(AI_message_output);
                head_animation(false);
                break;
            case "input_audio_buffer.speech_started":
                head_animation(true);
                break;
            case "response.audio_transcript.done":
                break;
            default: //there are many message types we don't need to proccess like "audio delta" etc.
                // log('Unhandled message type', AI_message.type);
                break;
        }
    } catch (error) {
        log('Error processing message' + error.message);
    }
}

async function btn_Stop_realtime_session() {
    if (peerConnection) {
        peerConnection.close();
        peerConnection = null;
    }
    if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
        audioStream = null;
    }
    if (dataChannel) {
        dataChannel.close();
        dataChannel = null;
    }
    // startButton.disabled = false;
    // stopButton.disabled = true;
}

////////////////////// FUNCTIONS FOR AI //////////////////////
async function tell_AI_about_additional_functions() {
    const function1 = {
        "type": "function",
        "name": "next_word",
        "description": "Switch to the next word or expression the user is interested in",
        // "parameters": parameters
    }
    const function2 = {
        "type": "function",
        "name": "show_images",
        "description": "Show images of the word or expression the user is interested in",
        // "parameters": parameters
    }
    let functions = [function1, function2];
    await create_function_description_for_AI(functions)
}

async function create_function_description_for_AI(functions) {
    const function_descriptions = {
        "type": "session.update",
        "session": {
            "tools": functions,
            "tool_choice": "auto"
        }
    };
    await send_message_to_AI(function_descriptions);
}

async function call_function_and_let_AI_know(AI_message_output) {
    console.log('call_function_and_let_AI_know');

    // log('AI_message_output', AI_message_output);
    // log('AI_message_output.type', AI_message_output?.type);
    // log('AI_message_output.name', AI_message_output?.name);

    try {
        if (AI_message_output &&
            AI_message_output?.type === "function_call" &&
            AI_message_output?.call_id) {

            log('AI_message_output.name', AI_message_output.name);
            switch (AI_message_output?.name) {
                case "next_word":
                    await btn_Next_Word_onClick(false);
                    await create_and_send_message_to_AI("Let the user know a next word has been loaded. Give the user an example with the " + app_state.word_type + ": " + app_state.expression + " and ask a follow up question, using the " + app_state.word_type + ".");
                    break;

                case "show_images":
                    await loadImages(true);
                    await create_and_send_message_to_AI("Let the user know images have been loaded.");
                    break;

                default:
                    log('Unhandled function name', AI_message_output.name);
                    break;

            }
        }
        // Request new response
        // send_Message_to_AI({ "type": "response.create" });
    } catch (error) {
        showNotification('Error with a called function')
        console.error('Error with a called function: ' + error.message);
    }
}


////////////////////// UI UPDATE FUNCTIONS //////////////////////

// Message handling functions
function updateTranscript(message) {
    if (message.response?.output?.[0]?.content?.[0]?.transcript) {
        set_Text_to_Translate(message.response.output[0].content[0].transcript, settings.auto_translation.value);
        number_of_processed_characters_by_AI += message.response.output[0].content[0].transcript.length;
        // transcriptDiv.textContent += message.response.output[0].content[0].transcript + ' ';
    }
}

async function speaking_monitoring() {
    log("speaking_monitoring");
    const silence_threshold = 0.3; // sec
    const check_interval = 0.1; // sec
    let silence_count = 0;
    let last_speech_time = new Date(); // Last datetime when the user or AI spoke
    const max_silence_time = 20; //sec

    while (true) {
        try {
            if (!app_state.audio_recording) {
                head_animation(false);
                return;
            }

            peerConnection.getStats(null).then((stats) => {
                stats.forEach((report) => {

                    // Check if the user speaks
                    if (report.type === "media-source" && report.kind === "audio") {
                        if (report.audioLevel > 0.1) {
                            last_speech_time = new Date();
                        }
                    }

                    // Check if AI speaks
                    if (report.type === "inbound-rtp" && report.kind === "audio") {
                        if (report.audioLevel > 0.1) {
                            // log("AI is speaking");
                            last_speech_time = new Date();
                            head_animation(true);
                            silence_count = 0;
                        } else {
                            // log("AI is NOT speaking");
                            silence_count++;
                            if (silence_count > silence_threshold / check_interval)
                                head_animation(false);
                        }
                    }

                    // If nobody has not spoken for X seconds - stop the session
                    const now = new Date();
                    const time_dif_sec = (now - last_speech_time) / 1000;
                    if (time_dif_sec > max_silence_time) {
                        btn_StopConversation_onClick();
                        showNotification("Call was ended due to inactivity")
                    }
                });
            });
            await wait(check_interval * 1000);
        } catch (error) {
            console.error('Error with peerConnection.getStats:', error);
            head_animation(false);
            return;
        }
    }
}

async function set_call_button_status(start) {
    let display = 'none';
    if (start) {
        document.getElementById("btn_call").src = call_hang_up_icon;
        // await wait(500); //delay the animation to wait until the mic is on [ms] -> lose some recording time
        display = 'block';
    } else {       //stop recording
        document.getElementById("btn_call").src = call_icon;
    }

    // document.getElementById("pulse1").style.display = display;
    // document.getElementById("pulse2").style.display = display;
}

// log AI realtime conversation usage every X seconds
async function start_AI_usage_logging(seconds = 60) {
    AI_realtime_usage_timer = setInterval(() => {
        log('log_AI_realtime_usage triggered');
        if (!app_state.audio_recording) return;

        log_AI_realtime_usage()

    }, seconds * 1000);

}

async function log_AI_realtime_usage() {
    let formData = new FormData();
    let metadata = {
        number_of_used_characters: number_of_processed_characters_by_AI,
    };
    number_of_processed_characters_by_AI = 0;
    metadata = add_parameters_to_json(metadata);
    metadata = JSON.stringify(metadata);
    formData.append('metadata', metadata);
    request_and_validate("log_usage_data", 'POST', formData);
}

////////////////////// OLD FUNCTIONS //////////////////////

export async function turn_on_mic() {
    await MP3recorder.start();
    app_state.audio_recording = true;
    stop_Rec_In_X_seconds();
}

export async function turn_off_mic() {
    app_state.audio_recording = false;
    MP3recorder.stop();
}

async function sendRecording(blob) {
    // return;
    // if (!blob)
    //   blob = new Blob(audioData, { type: 'audio/' + audio_format });

    var formData = new FormData();
    formData.append('file', blob, 'recording.' + audio_format);

    // downloadRecording(blob);

    // let messages = [];
    let messages = [
        {"role": "system", "content": "You are a helpful assistant."},
        {
            "role": "system",
            "content": "Please provide concise responses not longer than " + max_ai_response_length + " symbols."
        },
    ];
    // messages.push({ "role": "system", "content": "The user is interested in the " + app_state.word_type + " \"" + app_state.expression + "\"" })
    // messages.push({ "role": "system", "content": "The user might use the " + app_state.word_type + " \"" + app_state.expression + "\"" })
    messages.push({
        "role": "system",
        "content": "I might later use the " + app_state.word_type + " \"" + app_state.expression + "\""
    })
    if (should_praise_user()) {
        messages.push({"role": "system", "content": get_praise_for_studying()});
        balloons(); //congratulation
    }
    messages.push({
        "role": "system",
        "content": "After your response, please ask a follow up question, using the " + app_state.word_type + " " + app_state.expression + " is mandatory"
    })
    if (app_state.last_user_request) messages.push({"role": "user", "content": app_state.last_user_request});
    if (app_state.AI_response) messages.push({"role": "system", "content": app_state.AI_response});

    setVoices();

    let metadata = {
        messages: messages,
        model: open_ai_chat_model,
        request_type: request_types.audio_to_audio,
        max_response_length: max_ai_response_length,
    };
    metadata = add_parameters_to_json(metadata);
    metadata = JSON.stringify(metadata);
    formData.append('metadata', metadata);

    await ask_AI_and_pronounce(formData);
    // await askAI_voice_new(formData);

    // await askAI_voice2("voice", "POST", formData);
}

async function downloadRecording(blob) {
    // if (!blob)
    //   blob = new Blob(audioData, { type: 'audio/' + audio_format });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = 'recording.' + audio_format;
    a.click();
    URL.revokeObjectURL(url);
    log("Download recording");
};