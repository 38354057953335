import React from "react";
import { updateVocabState } from "./sub_pages/vocabulary";
import { getFromStorage } from "./elements/storage";
import { load_JWT } from "./elements/google_sso_login";
import { getLangNameByCode, log, showSubPage } from "./elements/helpers";
import { load_2Checkout_script } from "./payment/2Checkout_form";
import { Open_Payment_Page } from "./sub_pages/payment";
import {
    app_state,
    btnOpenSettings_onClick,
    initialize_Setting, learning_languages,
    loadSettingsFromDB,
    settings
} from "./sub_pages/settings";
import { click_outside_handler } from "./buttons/click_outside";
import { initialize_podcast } from "./elements/podcast";
import { translation_languages } from "./resources/translation_languages";
import { pop_words_17k } from "./resources/pop_words_17k";
import Auto_Complete_Input, { initialize_Autocompletion } from "./elements/autocmoplete";
import { pop_words_20k_RU } from "./resources/pop_words_20k_RU";

export const debug_mode = (process.env.REACT_APP_DEBUG === "true"); //default - false
export const localSetup = false; //default - false
export const remind_rate = 50; //%
// export const ask_questions = false;

// export const keep_logged_in = true;
const app_version = '0.9.250';

// require('dotenv').config();
// const backendUrl = process.env.BACKEND_URL;
export let server_URL = process.env.REACT_APP_BACKEND_URL;
// export let server_URL = "https://server.english-ai.org";
// export let server_URL = "https://server.WRONG";
// export let server_URL = "https://server.easy-english.click";
// if (localSetup) server_URL = "http://192.168.100.104:8000"
if (localSetup) server_URL = "http://localhost:8000"
let loading = false;
const url = new URL(window.location.href)
// let initialized = false;

// Features under development
// export let manual_question_control = false;

export default class App_settings extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await initialize();
    }

    render() {
        return;
    }
}

async function initialize() {

    await get_latest_app();

    if (loading) return;
    else loading = true;

    log(server_URL);
    log(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

    console.log("App version: " + app_version);
    if (localSetup) console.log("!!!Local setup is being used!!!")

    await getLanguage_from_Browser();
    await load_JWT();

    await dynamic_URL(url);
    // if(url.search)
    //     await dynamic_URL(url);
    // else
    //     app_state.expression = await getFromStorage("expression");
    // log("app_state.expression: " + app_state.expression);
    await loadSettingsFromDB();
    await initialize_podcast();
    click_outside_handler();
    load_2Checkout_script();
    window.Test_Env_Warning.setState({ "updated": true });

    hide_top_menu();
    hide_bottom_menu();

    loading = false;
}

async function get_latest_app() {
    log("get_latest_app");
    try {
        const index_file_last_load = try_get_URL_param("v");
        if (!index_file_last_load) {
            window.location.href = get_URL_with_updated_version();
        }
        const timeDifference = new Date().getTime() - index_file_last_load;
        const hourDifference = timeDifference / (1000 * 60 * 60); // ms to hours
        // log("hourDifference", hourDifference);
        if (hourDifference > 1) // reload the app every 1 hour
            window.location.href = get_URL_with_updated_version();
    }
    catch (error) {
        console.error("Error in get_latest_app: ", error.message);
    }
}

function get_URL_with_updated_version() {
    const url = new URL(window.location.href); // current URL

    // Check if the pathname ends with a slash (e.g., "/") and does not contain a file name
    if (url.pathname.endsWith('/') && !url.pathname.endsWith('/index.html')) {
        url.pathname += 'index.html'; // Add "index.html" to the pathname
    }

    const params = new URLSearchParams(url.search); // get all query params
    params.set('v', new Date().getTime());   // Update index.html version parameter
    url.search = params.toString(); // save the params

    log("url", url);
    return url;
}

async function getLanguage_from_Browser() {
    let lang_code = window.navigator.language;
    if (lang_code) {
        if (lang_code.includes("-")) //remove second part (e.g. "en-US")
            lang_code = lang_code.substring(0, lang_code.indexOf("-"));

        if (await getLangNameByCode(translation_languages, lang_code)) { //check the language exists in the list
            // log("lang_code: " + lang_code);
            settings.native_language.value = lang_code;
            await initialize_Setting(settings.native_language.name, false); // reload the control
        }
    }
}

async function dynamic_URL(url) {
    // http://localhost:3000/?lang=fr-FR&text=hello&page=div_payment
    let text = try_get_URL_param('text');
    if (text)
        app_state.expression = text;
    else
        app_state.expression = await getFromStorage("expression");

    let language = try_get_URL_param('lang')
    switch (language) {
        case learning_languages.fr_FR:
        case learning_languages.ru_RU:
            settings.learning_language.value = language;
            initialize_Autocompletion(pop_words_20k_RU);
            break;
        default:
            settings.learning_language.value = learning_languages.en_US;
            initialize_Autocompletion(pop_words_17k);
            break;
    }
    log("language: " + settings.learning_language.value);

    let page = try_get_URL_param('page')
    switch (page) {
        case !page:
            break;
        case "div_payment":
            // https://www.english-ai.org/?page=div_payment&status=failure
            let status = try_get_URL_param('status')

            if (status && status === "failure")
                await Open_Payment_Page(null, true);
            else
                await Open_Payment_Page(null, false);
            break;

        case "div_settings":
            await btnOpenSettings_onClick();
        default:
            // https://www.english-ai.org/?page=div_settings
            await showSubPage(page);
            break;
    }
}

function try_get_URL_param(param_name) {
    try {
        return url.searchParams.get(param_name);
    }
    catch {
        return false;
    }
}

function hide_top_menu() {
    let lastScrollTop = 0;
    const topMenu = document.getElementById('div_top_menu');
    const menuHeight = topMenu.offsetHeight; // Get the height of the menu
    let menuOffset = 0;

    window.addEventListener('scroll', function () {
        let scrollTop = window.scrollY || document.documentElement.scrollTop;

        // Calculate the scroll direction
        let scrollDelta = scrollTop - lastScrollTop;

        // Adjust menuOffset based on scroll speed (up or down)
        menuOffset = Math.min(menuHeight, Math.max(0, menuOffset + scrollDelta));

        // If scrolling down, move the menu up
        if (scrollDelta > 0) {
            topMenu.style.transform = `translateY(-${menuOffset}px)`;
        }
        // If scrolling up, move the menu down
        else {
            topMenu.style.transform = `translateY(-${menuOffset}px)`;
        }

        lastScrollTop = scrollTop;
    });
}

function hide_bottom_menu() {
    const bottomMenu = document.getElementById('div_bottom_menu');
    if(!bottomMenu) return;

    let lastScrollTop = 0;
    let menuOffset = 0;
    const menuHeight = bottomMenu.offsetHeight;

    window.addEventListener('scroll', function () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let scrollDelta = scrollTop - lastScrollTop;

        // Update menuOffset based on scroll speed
        menuOffset = Math.min(menuHeight, Math.max(0, menuOffset - scrollDelta));

        // Apply the transformation based on menuOffset to move the menu up or down
        bottomMenu.style.transform = `translateY(${menuOffset}px)`;

        // Update lastScrollTop for the next scroll event
        lastScrollTop = scrollTop;
    });
}
