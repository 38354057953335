//Based on EmailJS - https://www.emailjs.com/
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {JWT_decoded, user_id_in_DB} from "./google_sso_login";
import {debug_mode} from "../app_settings";
import {log} from "./helpers";
import {wait} from "@testing-library/user-event/dist/utils";

export const Feedback_Form = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        let _user_id = "user_id:" + user_id_in_DB;
        let message = document.getElementById("message").value;
        let user_email = document.getElementById("user_email").value;

        emailjs.send('service_33v14ro', 'template_ur87fyd', {
            "user_id": _user_id,
            "message": message, "user_email": user_email
        }, 'IUF5JWSViFndSL9X2')
            .then((result) => {
                log(result.text);
            }, (error) => {
                console.error(error.text);
            });

        document.getElementById("lbl_user_notification").style.display = "block";
        document.getElementById("div_feedback_form").style.display = "none";
    };



    return (
        <div style={{marginTop: "40px"}}>
            <label className="label">Send feedback</label>

            <form ref={form} onSubmit={sendEmail} style={{textAlign: "center"}}>
                <div id="div_feedback_form" className="div_feedback_content" style={{textAlign: "center"}}>
                    <textarea id="message" className="textarea textarea_feedback"
                              placeholder="Any issues or suggestions" rows={5} required="required"/>
                    <input id="user_email" type="email" className="input input_settings"
                           placeholder="email address (optional)"/>
                    <button id="btn_send_feedback" type="submit" className="button button_primary" value="Send" style={{marginTop: "1em"}}>Send</button>
                </div>

                <label id="lbl_user_notification" className="label_notification">Thanks for the feedback!</label>
                {pre_fill_Feedback_Form()}
            </form>
        </div>
    );
};

// It works only sometimes
function pre_fill_Feedback_Form(){
    if(JWT_decoded) {
        document.querySelectorAll('#user_email').forEach(el => {
            el.value = JWT_decoded["email"]; // Set the value to null
        });

        // document.getElementById("user_email").value = JWT_decoded["email"];
        // log("user_email: " + JWT_decoded["email"]);
        // setDisplayValue("user_email", "none");
    }
    return <div/>;
}