import React, {Component} from 'react';
import head_icon from "../../images/talking_head_512.png";
import {audio_buffer_url, playSound, TTS_Pause_Playing, TTS_Reset} from "./tts";
import {app_state} from "../sub_pages/settings";
import {Spinner, SpinnerBig} from "./spinner";
import { update_Controls } from '../main_page';

export default class Talking_head extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="pulse_container" style={{paddingTop: "1rem"}}>
                {/*<img src="https://app.talkpal.ai/static/media/Oval.33e755bab79b94da923b.png" style={{zIndex: "2"}}/>*/}
                <img src={head_icon} onClick={replay_last_response} style={{zIndex: "2", width: "10rem"}}/>
                {/*<Spinner/>*/}
                <div style={{display: "none"}} id="spr_loading_data"><SpinnerBig/></div>
                {/*<SpinnerBig id="spr_loading_data" style={{display: "none"}}/>*/}
                <div id="pulse3" className="pulse-bg-3 pulse-big" style={{display: "none", zIndex: "1"}}></div>
                <div id="pulse4" className="pulse-bg-4 pulse-big" style={{display: "none", zIndex: "1"}}></div>
            </div>
        );
    }
}

export async function replay_last_response() {
    app_state.state = app_state.states.reading_text;
    update_Controls();

    await TTS_Pause_Playing();
    await TTS_Reset();
    await playSound(app_state.AI_response_audio);

    app_state.state = app_state.states.none;
    update_Controls();
}

export async function head_animation(start){
    let display = 'none';
    if(start){
        display = 'block';
    }
    document.getElementById("pulse3").style.display = display;
    document.getElementById("pulse4").style.display = display;
}