import React from 'react';
// import { InView } from "react-intersection-observer";
import { log, setDisplayValue, translate } from "./helpers";
import { debug_mode } from "../app_settings";
import { Spinner } from "./spinner";
import { app_state, settings } from "../sub_pages/settings";
import say_image from "../../images/Speaker_512_v2.png";
import { replay_last_response } from "./talking_head";
import common_scss from "../../scss/common.scss"

let text_to_translate;
let last_translated_text;
let user_sees_translation_box; // true if the translation textbox is visible to the user

let lbl_user_request;
let lbl_AI_text;
let lbl_translation;
let div_text_and_translation;
let btn_Translate;
let inView;

export let translation_status;
export const translation_statuses = {   
    Reset: 'Reset',
    Loading: 'Loading',
    Loaded: 'Loaded',
};

export default class Translation_block extends React.Component {

    componentDidMount() {
        lbl_user_request = document.getElementById('lbl_user_request');
        lbl_AI_text = document.getElementById('lbl_AI_text');
        lbl_translation = document.getElementById('lbl_translation');
        div_text_and_translation = document.getElementById('div_text_and_translation');
        btn_Translate = document.getElementById('btn_Translate');

        // inView = document.getElementById('inview_id');
        // inView.threshold(0.5);
        // inView.threshold(50);
        // inView.defaultOptions = {
        //     threshold: 0.5,
        //     offset: 100
        // };
    }

    render() {
        return (
            <div>
                {/*<div style={{display: "none", marginTop: "1em"}} id="spr_loading_data"><Spinner/></div>*/}

                <div id="div_text_and_translation" className="div_text_and_translation">
                    <div className="div_vertical" style={{ alignItems: "flex-end" }}>
                        <label id="lbl_user_request" className="textarea textarea_text textarea_text_request" style={{ textAlign: 'left' }}></label>
                    </div>

                    <div className="div_vertical">
                        <div className="div_horizontal" >
                            <label id="lbl_AI_text" className="textarea textarea_text"></label>
                            <img id="btn_Read_Text" onClick={replay_last_response} src={say_image} className='button_read_text' />
                        </div>
                        <button className="button button_action button_secondary" id="btn_Translate"
                            onClick={btn_Translate_onClick}
                            // style={{ float: "right", marginTop: "1em" }}
                        >Translate
                        </button>
                    </div>

                    <div style={{ display: "none", marginTop: "1em" }} id="spr_translation_loading"
                        className="div_vertical">
                        <Spinner />
                    </div>

                    <div className="div_horizontal" style={{ marginTop: "0.5em" }}>
                        <label id="lbl_translation" className="textarea textarea_translation"></label>
                        {/* Just to have the same margin from the left */}
                        <img id="btn_Read_Text_hidden" src={say_image} className='button_read_text_hidden'/>
                    </div>
                </div>
            </div>
        )
    }
}

async function btn_Translate_onClick() {
    await showTranslation(lbl_AI_text.innerText);
}

export async function set_Text_to_Translate(text, show_translation = true, user_request = null) {
    log('set_Text_to_Translate');
    // log('text: ' + text);

    if (app_state.state === app_state.states.podcast_pausing) return;

    // Adjust controls

    // Highlight studying words
    // const wordToColor = app_state.expression + "(s|ing)?"; //highlight words with s / ing at the end
    // const regex = new RegExp(`(${wordToColor})`, 'gi');
    // const color = common_scss.prim_color.toString();
    // const text_html = text.replace(regex, '<span style="color:' + color + '; font-weight:bold">$1</span>');
    lbl_AI_text.innerHTML = get_HTML_text_with_highlighed_word(text);

    if (user_request) {
        log("user_request", user_request);
        setDisplayValue('lbl_user_request', "block");
        lbl_user_request.innerHTML = get_HTML_text_with_highlighed_word(user_request);
    }
    else {
        setDisplayValue('lbl_user_request', "none");
    }

    if (text) {
        set_translation_control_status(translation_statuses.Reset);
        setDisplayValue('div_text_and_translation', "block");
    }
    else {
        setDisplayValue('div_text_and_translation', "none");
        return;
    }

    if (settings.auto_translation.value)
        showTranslation(text)

    // if(user_sees_translation_box && show_translation)
    // if(show_translation)
    //     showTranslation(text);
    // else {
    //     lbl_translation.innerText = ""; // Clear the translation
    //     lbl_translation.style.display = "none";
    // }
}

function get_HTML_text_with_highlighed_word(text) {
    if(!app_state.expression || !text) return text;

    const wordToColor = app_state.expression + "(s|ing)?"; //highlight words with s / ing at the end
    const regex = new RegExp(`(${wordToColor})`, 'gi');
    const color = common_scss.prim_color.toString();
    const text_html = text.replace(regex, '<span style="color:' + color + '; font-weight:bold">$1</span>');
    return text_html;
}

async function showTranslation(text) {
    if (debug_mode) console.log('showTranslation');

    set_translation_control_status(translation_statuses.Loading);
    const translated_text = await translate(text);
    if (!translated_text) {
        set_translation_control_status(translation_statuses.Loaded);
        return false;
    }

    lbl_translation.innerText = translated_text;
    // lbl_translation.style.display = "block";
    // btn_Translate.style.display = "none";

    last_translated_text = text;
    set_translation_control_status(translation_statuses.Loaded);
}

// const lbl_translation_InView_onChange = async event => {
//     return;
//     if (!settings.auto_translation.value) return;
//     // if (debug_mode) console.log('Inview:', JSON.stringify(event));

//     // showNotification()

//     user_sees_translation_box = event;
//     if (text_to_translate !== last_translated_text)
//         await showTranslation(text_to_translate);
// }

export function set_translation_control_status(status) {
    log("set_translation_control_status: " + status);
    translation_status = status;

    switch (status) {
        case translation_statuses.Reset:
            lbl_translation.innerText = ""; // remove previous translation
            setDisplayValue('spr_translation_loading', "none");
            setDisplayValue('lbl_translation', "none");
            setDisplayValue('btn_Translate', "block");
            break;
        case translation_statuses.Loaded:
            setDisplayValue('spr_translation_loading', "none");
            if (document.getElementById('lbl_translation').innerText) //Show only if there is a translation
                setDisplayValue('lbl_translation', "block");
            break;
        case translation_statuses.Loading:
            setDisplayValue('spr_translation_loading', "flex");
            setDisplayValue('lbl_translation', "none");
            setDisplayValue('btn_Translate', "none");
            break;
        default:
        // code block
    }
}
